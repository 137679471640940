$Neutral-900: #000a13;
$Neutral-Blue-900: #000013;
$Neutral-Blue-700: #292979;
$Neutral-Blue-25: #f5f5ff;
$Neutral-Grey-1000: #0d0d0d;
$Neutral-Grey-50: #fafafa;
$Neutral-Grey-800: #3c3c3c;
$Neutral-100: #ccd3d2;
$Neutral-0: #fffdf1;
$Neutral-Blue-0: #ffffff;
$Neutral-Blue-50: #f1f1f3;
$Neutral-Blue-100: #ccccd3;
$Neutral-Blue-200: #9999B3;
$Neutral-Blue-300: #666693;
$Pink-500: #ff3979;
$Pink-900: #470017;
$Orange-500: #ffa800;
$Blue-900: #10103b;
$Blue-800: #15154f;
$Blue-700: #292979;
$Blue-500: #2121ff;
$Blue-400: #7070ff;
$Green-500: #72d84e;
$Green-900: #134700;
$Overlay-15: rgba(255, 255, 255, 0.15);
$Overlay-W-5: rgba(255, 255, 255, 0.05);
$Yellow-500: #ffd400;
$Yellow-900: #473c00;
$Neutral-Grey-900: #252525;
